
// @ts-nocheck

const loadOptions = async (search, prevOptions, subCategory) => {
  let filteredOptions;
  let hasMore = false
try {
  if (!search) {
    
    filteredOptions = subCategory;
  
  } else {

    const searchLower = search.toLowerCase();

    filteredOptions = subCategory.filter((subCat) => {
      if (subCat.label.toLowerCase().includes(searchLower)) {
        return subCat
      }
    });

    
  }
} catch (error) {
  console.log(error);
  
}





  return {
    options: filteredOptions,
    hasMore:hasMore,
  };
};

export default loadOptions;