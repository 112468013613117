
// @ts-nocheck
"use client"
import React, { useCallback } from 'react'
import { AsyncPaginate ,} from 'react-select-async-paginate'
import loadOptions from './loadOptions'
import { Category, SubCategory } from '@/graphql/generated/schema'
import { usePathname, useSearchParams } from 'next/navigation'
import { useRouter } from 'next13-progressbar'

export default function GridSubCategorySelect({ value, subCategory, 
  // onChangeFunc,
   currentCategory }: {
  subCategory: {label:string, value:string}[],
  currentCategory: Category,

}) {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()


  const onChange =(newVal)=> {
    if (newVal?.value) {
      
     router.push(`?${currentCategory.id}=${newVal.value}#${currentCategory.id}`)
    }else{
      
router.push("/")

    }
    
  }


  return (
    <>
      <AsyncPaginate isClearable
aria-label={"Select a subcategory"}
        className='asyncpaginationselect '
        isLoading={false}
        loadingMessage={''}
        // value={value}
        loadOptions={(search, prevOptions) => loadOptions(search, prevOptions, subCategory)}
        onChange={onChange}
      />
    </>
  )
}